// import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { useEffect, useState } from "react";

function App() {
  const [bkg, setBkg] = useState(null);
  const [qr, setQr] = useState(null);
  const load = async () => {

    for (let i = 0; i < Math.random()*10; i++) 
      Math.random()  

    const res = await fetch("https://media.suwenxuan.cn/posters/1");
    const info = await res.json();

    setBkg("https://media.suwenxuan.cn/" + info.background.url);
    let s = 0;
    for (let i = 0; i < info.qrs.length; i++) {
      const element = info.qrs[i];
      element.ss = s;
      s += element.chance;
      element.se = s;
    }
    console.log(s, Math.random() * s);
    console.log(info.qrs);
    const rand = Math.random() * s;
    for (let i = 0; i < info.qrs.length; i++) {
      const element = info.qrs[i];
      console.log(i,element,rand)
      if (rand >= element.ss && rand <= element.se){
        setQr("https://media.suwenxuan.cn/" + element.image.url);
        console.log("select",i,"https://media.suwenxuan.cn/" + element.image.url)
        break;
      }
    }
  };
  useEffect(() => {
    load();
  });

  return (
    <div className="container mx-auto">
      <img
        className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 h-qr"
        src={qr}
        alt="qr"
      ></img>
      <img className="object-cover h-screen mx-auto" src={bkg} alt="bkg"></img>
      {/* <span>{Math.random()}</span> */}
    </div>
  );
}

export default App;
